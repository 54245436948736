<template>
    <div class="fee-info">
        <StackRouterHeaderBar />
        <div class="title" v-html="title" />
        <table>
            <tbody>
                <tr v-for="(tr, idx) in Object.keys(feeTable)" :key="idx">
                    <td class="key">
                        <div>{{ $translate(`MANAGER_${tr.toUpperCase()}`) }}</div>
                        <div class="event-banner" v-if="idx === 2">사전 예약 이벤트</div>
                    </td>
                    <td class="value" v-html="feeTable[tr]"></td>
                </tr>
            </tbody>
        </table>
        <div class="deposit-desc" @click="onClickDeposit">
            <i class="material-icons f-17 m-r-8">help_outline</i>
            <span class="f-14">매너 보증금이 뭔가요?</span>
        </div>
        <div class="fee-policy">
            <div class="f-16 c-black m-b-20">매너 보증금 & 환불 안내</div>
            <div class="policy" v-for="i in 3" :key="`${i + 100}`">
                <div class="dot m-r-8" />
                <div class="text" v-html="$translate(`MANAGER_REFUND_POLICY_${i}`)" />
            </div>
        </div>
        <BottomButton :label="`보증금 입금하기`" @click="purchaseVirtualAccount" />
    </div>
</template>

<script>
import productService from '@/services/product'

export default {
    name: 'ManagerFeeInfoPage',
    data: () => ({
        deposit: null,
    }),
    async mounted() {
        const { manager_matching: deposit } = await productService.all()
        this.deposit = this.me.id % 2 ? deposit[0] : deposit[1]
    },
    computed: {
        me() {
            return this.$store.getters.me
        },
        title() {
            return `<span class='c-primary f-bold'>매니저 매칭</span><br>비용 안내`
        },
        feeTable() {
            const entrance = '완전 무료'
            const deposit = this.me.id % 2 ? '10만원' : '5만원'

            let perOnceDefault = this.$translate('MANAGER_PERONCE_DEFAULT')
            let perOnce
            if (this.me.gender === 0) {
                perOnce = this.me.profile.marry ? '148,000' : '128,000'
            } else {
                perOnce = this.me.profile.marry ? '88,000' : '68,000'
            }
            perOnceDefault = perOnceDefault.replace(/%s/, perOnce)
            perOnceDefault += `<span class='c-primary f-12 f-medium'>선착순 100명 첫회 무료 매칭!</span>`

            return {
                entrance,
                deposit,
                perOnce: perOnceDefault,
            }
        },
    },
    methods: {
        async purchaseVirtualAccount() {
            const idx = await this.$modal.basic({
                body: '매너 보증금과 매칭 비용 안내를 확인하고 진행해주세요',
                buttons: [
                    {
                        label: 'CANCEL',
                        class: 'btn-default',
                    },
                    {
                        label: 'CONFIRM',
                        class: 'btn-primary',
                    },
                ],
            })

            if (!idx) return

            const gender = this.$store.getters.me.gender === 0 ? 'male' : 'female'
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'Try_Purchase_by_virtual',
                    option: {
                        product: this.deposit.code,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendAirbridgeEvent',
                value: {
                    category: `purchase_${gender}`,
                    option: {
                        type: 'custom',
                        value: this.deposit.price,
                    },
                },
            })
            this.$nativeBridge.postMessage({
                action: 'sendPurchaseEvents',
                value: {
                    gender: gender,
                    item: this.deposit,
                },
            })
            try {
                const data = await productService.createOrder(this.deposit.id, this.deposit)
                this.$stackRouter.push({
                    name: 'VirtualAccountPage',
                    props: { userVirtualAccount: data.user_virtual_account },
                })
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onClickDeposit() {
            this.$nativeBridge.postMessage({
                action: 'openInAppBrowser',
                value: `https://vbproduct.notion.site/a35bcad6544f47ab810894e46ca5363b`,
            })
        },
    },
}
</script>

<style scoped lang="scss">
.fee-info {
    height: calc(100vh - 81px);
    overflow-y: auto;

    .title {
        font-size: 24px;
        line-height: 44px;
        color: black;
        padding: 0 16px;
        margin-bottom: 40px;
    }
    .deposit-desc {
        background: $grey-01;
        border: 1px solid $grey-03;
        width: fit-content;
        padding: 6px 18px;
        border-radius: 18px;
        margin: 20px auto 40px;

        span {
            color: $grey-09;
        }
        @include center;
    }
    .fee-policy {
        background: $grey-01;
        padding: 25px 16px;
        color: $grey-09;

        .policy {
            display: flex;
            font-size: 12px;
            margin-bottom: 10px;
        }
    }
    table {
        width: 100%;
        border: 1px solid $grey-02;
        border-bottom: none;
        border-spacing: unset;

        .key {
            border-right: 1px solid $grey-02;
            border-bottom: 1px solid $grey-02;
            padding: 15px;
            font-size: 14px;
            width: 37%;

            .event-banner {
                font-size: 12px;
                background: $grey-02;
                padding: 1px 8px;
                width: fit-content;
                color: black;
                border-radius: 6px;
                margin-top: 7px;
                @include center;
            }
        }
        .value {
            text-align: center;
            color: black;
            border-bottom: 1px solid $grey-02;
        }
    }
}
</style>
